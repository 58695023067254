<template>
<div v-loading.fullscreen="fullscreenLoading">
  <div class="top-tip-wrapper" v-if="order">
    <div class="top-tip-wrapper-inner">
      <div class="order-info">
        <span class="icon el-icon-bell"></span>
        <span class="content">{{$t('message.checkout.billingAddressTip', { billingAddress: order && order.billingDescription})}}
        </span>
      </div>
    </div>
  </div>
  <div class="checkout-wrapper">
    <div class="payment-wrapper">
      <div class="payment-content order-box margin-top-20">
        <div>
          <div class="title">{{$t('message.checkout.paymentMethod')}}</div>
          <div class="padding-top-20 mobile-padding">
            <div class="payment-information">
              <el-image :src="order && order.logo" class="logo" fit="contain"></el-image>
              <div class="qrcode-info">
                <div class="qrcode">
                  <el-image :src="qrcode" class="qrcode-img" fit="contain"></el-image>
                </div>
                <div class="scan-text">{{$t('message.checkout.scanCode')}}</div>
              </div>
            </div>
            <div class="information-more">
              <div class="item">{{$t('message.checkout.moreInfo')}}：</div>
              <div class="item">{{$t('message.checkout.orderno')}}：{{ order && order.txnId }}</div>
              <div class="item">{{$t('message.checkout.txnTime')}}：{{ order && order.tradeTime }}</div>
              <!-- <div class="item">{{$t('message.checkout.moreInfo')}}：xxxpppp</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="product-content order-box margin-left-25 margin-top-20 margin-bottom-70">
        <!-- 订单信息 -->
        <div class="order-summary-wrapper">
          <div class="title">{{$t('message.checkout.summary')}}</div>
          <div class="padding-left-20 padding-right-20 padding-bottom-24 padding-top-16 mobile-padding">
            <div class="summary-item">
              <label>{{$t('message.checkout.orderno')}}:</label>
              <div class="content">{{ order && order.txnId }}</div>
            </div>
            <div class="summary-item">
              <label>{{$t('message.checkout.website')}}:</label>
              <div class="content">{{ order && order.webSite }}</div>
            </div>
            <div class="summary-item">
              <label>{{$t('message.checkout.amount')}}:</label>
              <div class="content amount">{{ order && order.currency }} {{ order && order.amount }}</div>
            </div>
            <div class="btns margin-top-23">
            <el-button @click="submit" type="primary" class="btn-submit">{{$t('message.checkout.paymentCompleted')}}</el-button>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns mobile">
      <el-button @click="submit" type="primary" class="btn-submit">{{$t('message.checkout.paymentCompleted')}}</el-button>
    </div>
  </div>
</div>
</template>
<script>
import { getPaymentInfo } from '@/api/aggregate';
import { getStatus, getBrandInfo } from '@/api/checkout';
import { getCheckoutType } from '@/utils/tools/ga';
import { exceptionShow } from '@/utils/tools/utils';

export default {
  data() {
    return {
      fullscreenLoading: true,
      order: {
        billingAddress: '',
        merchantTxnId: '',
        website: '',
        txnCurrency: '',
        txnAmount: '',
        logo: '',
      },
      qrcode: '',
      tryCount: 0,
      tryInterval: 15 * 1000,
      pending: 4,
      key: '',
      type: '',
    };
  },
  created() {
    this.key = this.$route.query.key ? this.$route.query.key : this.$route.params.id;
    this.type = getCheckoutType(this.$route.query.type, this.key);

    getBrandInfo(this.key).then((res) => {
      if (res.respCode === '20000' && res.data) {
        this.$store.dispatch('app/setBrandInfo', res.data);
      }
    }).catch(() => ({}));
    this.qrcode = this.$route.query.code;
    getPaymentInfo(this.key).then((res) => {
      if (res.respCode === '20000') {
        this.order = res.data;
      }
      this.fullscreenLoading = false;
    }).catch(() => {
      this.fullscreenLoading = false;
    });
    const timer = setTimeout(() => {
      this.getOrderStatus(true);
      clearTimeout(timer);
    }, this.tryInterval);
  },
  methods: {
    getOrderStatus(interval = false) {
      this.tryCount += 1;
      getStatus(this.$route.query.key ? this.$route.query.key : this.$route.params.id).then(res => {
        if (res.respCode === '20000') {
          if (res.data.status === 'S') { // 成功
            this.$router.push({
              path: '/result/success',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          } else if (res.data.status === 'F') { // 失败
            this.$router.push({
              path: '/result/failed',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          } else if (res.data.status === 'P' || res.data.status === 'R') { // 等待
            if (interval && this.tryCount < this.pending) { // 重试，若4次轮询(1分钟)后还没有结果，不再轮询，等待用户手动刷新
              const timer = setTimeout(() => {
                this.getOrderStatus(true);
                clearTimeout(timer);
              }, this.tryInterval);
            }
            // 如果是用户点击的已完成支付，订单还在P状态，则跳转到pending页面
            if (!interval) {
              this.$router.push({
                path: '/result/pending',
                query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
              });
            }
          } else {
            this.$router.push({
              path: '/result/failed',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
            });
          }
        } else {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, this.type)) {
            return;
          }
          this.$router.push({
            path: '/result/failed',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: this.type },
          });
        }
        this.fullscreenLoading = false;
      }).catch(() => {
        if (interval && this.tryCount < this.pending) { // 重试
          setTimeout(() => {
            this.getOrderStatus(true);
          }, this.tryInterval);
        }
        this.fullscreenLoading = false;
      });
    },
    submit() {
      this.fullscreenLoading = true;
      this.getOrderStatus();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/checkout.scss';
.payment-information {
  text-align: center;
  .logo {
    height: 52px;
  }
  .qrcode-info {
    background-color: $color-select-prefix-bg;
    border-radius: 10px;
    border: 1px solid $color-border-qrcode;
    margin: 15px auto 0;
    width: 190px;
    .qrcode {
      padding: 10px;
      .qrcode-img {
        width: 100%;
        height: auto;
        img {
          width: 100%;
        }
        display: block;
      }
    }
    .scan-text {
      font-size: 14px;
      font-weight: 400;
      color: $color-text;
      line-height: 20px;
      border-top: 1px solid $color-border-qrcode;
      padding: 10px 0;
    }
  }
}
.information-more {
  font-size: 14px;
  font-weight: 500;
  color: $color-text;
  line-height: 20px;
  background-color: $color-select-prefix-bg;
  padding: 2px 30px 47px;
  margin-top: 20px;
  .item {
    margin-top: 10px;
  }
}
.btn-submit {
  width: 100%;
}
.btns.mobile {
  display: none;
}
@media screen and (max-width: 1140px) {
  .btns {
    display: none;
    &.mobile {
      display: block;
      position: fixed;
      left: 0;
      bottom: 22px;
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      button {
        width: 100%;
      }
    }
  }
}
</style>
